<script setup lang="ts">
const props = defineProps<{
  item?: any
}>()

const emit = defineEmits(['change'])

const { t, locale } = useI18n()

const localePath = useLocalePath()

const host = await useGetHost('host')

const token: any = useCookie('token')

const headers: any = { domain: host, locale: locale.value }

const url = useRequestURL()

const loading = ref(false)
const files = ref()
const image = ref()
const error = ref('')

const screenshotUploaded: any = useScreenshotUploaded()

async function onUpload(e: any) {
  error.value = ''
  loading.value = true

  const file = e.target.files[0]

  // Upload
  const formData = new FormData()
  formData.append('file', file)

  if (screenshotUploaded.value)
    formData.append('screenshot', screenshotUploaded.value)

  const data: any = await $fetch('/api/upload-screenshot', {
    method: 'post',
    headers,
    body: formData,
  })

  if (data?.err) {
    if (data?.err === 500) {
      token.value = null
      toastAdd({ title: t('login_again_to_continue'), color: 'red', timeout: 5000 })
      await navigateTo({ path: localePath('/login'), query: { redirect: url.href } })
      return false
    }

    error.value = data?.err === 429
      ? t('screenshot_upload_ratelimit')
      : t('screenshot_upload_error')
  }

  loading.value = false

  // Return image url
  if (data && checkImage(data)) {
    // Preview image
    image.value = URL.createObjectURL(file)

    screenshotUploaded.value = data

    emit('change', data)
  }
}
</script>

<template>
  <div class="mx-auto max-w-screen-xl pt-2">
    <div class="container m-auto px-2 text-gray-600 md:px-4 xl:px-2">
      <input
        ref="files"
        type="file"
        :required="!!props?.item?.validate.includes('required')"
        :placeholder="_get(props?.item, `placeholder_${locale}`) ? _get(props?.item, `placeholder_${locale}`) : props?.item?.placeholder"
        :accept="_has(props?.item, 'accept') ? props?.item?.accept : ''"
        @change="onUpload"
      >

      <div class="mx-auto my-5">
        <div v-if="loading">
          <LoadingSpinner />
          <p class="mx-auto max-w-4xl text-center">
            {{ t('uploading_text') }}
          </p>
          <p class="mx-auto max-w-4xl text-center">
            {{ t('wait_continue') }}
          </p>
        </div>
        <UAlert
          v-else-if="error"
          :close-button="{ icon: 'i-heroicons-x-mark-20-solid', color: 'gray', variant: 'link', padded: false }"
          icon="i-heroicons-x-circle-solid"
          color="red"
          variant="soft"
          :title="error"
          :ui="{
            wrapper: 'my-5',
            title: 'text-lg mb-1',
            description: 'text-lg',
            icon: {
              base: 'h-12 w-12',
            },
          }"
          @close="error = ''"
        />
        <NuxtImg
          v-else-if="image"
          loading="lazy"
          :src="image"
          class="h-full w-full max-w-full object-cover"
        />
      </div>
    </div>
  </div>
</template>
