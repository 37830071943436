<script setup lang="ts">
import { destr } from 'destr'

const props = defineProps<{
  paymentMethods?: any
}>()

const emit = defineEmits(['change'])

const { t, locale } = useI18n()

const sitedata: any = useSiteData()

const cartKey = sitedata.value?.site_type === 4 ? 'cart-shop' : 'cart'

const cartStorageKey = computed(() => locale.value ? `${cartKey}-${locale.value}` : cartKey)

const cartStorage: any = import.meta.client ? destr(localStorage.getItem(cartStorageKey.value)) : {}

const cart: any = ref(cartStorage ?? {})

const selected = ref(cart.value?.payment_method ?? 1)

const paymentMethodsDefault = [{
  value: 1,
  label: t('bank_transfer'),
  icon: 'i-heroicons-information-circle',
  defaultOpen: (cart.value?.payment_method === 1),
  content: t('bank_transfer_note'),
}, {
  value: 101,
  label: t('balance_cash'),
  icon: 'i-heroicons-information-circle',
  defaultOpen: (cart.value?.payment_method === 101),
  content: t('balance_cash_note'),
}, {
  value: 100,
  label: t('cod'),
  icon: 'i-heroicons-information-circle',
  defaultOpen: (cart.value?.payment_method === 100),
  content: t('cod_note'),
}, {
  value: 2,
  label: 'Viettel Money',
  icon: 'i-heroicons-arrow-down-tray',
  defaultOpen: (cart.value?.payment_method === 2),
  content: '',
}, {
}, {
  value: 3,
  label: 'Vnpay QR',
  icon: 'i-heroicons-arrow-down-tray',
  defaultOpen: (cart.value?.payment_method === 3),
  content: '',
}, {
  value: 4,
  label: 'ATM/Internet Banking',
  icon: 'i-heroicons-eye-dropper',
  defaultOpen: (cart.value?.payment_method === 4),
  content: t('payment_information_secure'),
}, {
  value: 5,
  label: 'Credit/Debit Card',
  icon: 'i-heroicons-rectangle-group',
  defaultOpen: (cart.value?.payment_method === 5),
  content: t('payment_information_secure'),
}, {
  value: 6,
  label: 'Paypal',
  icon: 'i-heroicons-square-3-stack-3d',
  defaultOpen: (cart.value?.payment_method === 6),
  content: '',
}, {
  value: 7,
  label: 'ZaloPay',
  icon: 'i-heroicons-wrench-screwdriver',
  defaultOpen: (cart.value?.payment_method === 7),
  content: '',
}]

const paymentMethodsItems = ref(props?.paymentMethods ?? paymentMethodsDefault)

const authenticated = useAuthenticated()

if (!authenticated.value)
  paymentMethodsItems.value = paymentMethodsItems.value.filter((e: any) => e.value !== 101)

function onSelect(v: any) {
  selected.value = v

  emit('change', selected.value)
}
</script>

<template>
  <div class="mx-auto mt-6">
    <div class="mt-12 mb-6 block">
      <h3 class="text-2xl font-bold text-gray-700">
        {{ t('payment_method') }}
      </h3>
      <p v-if="sitedata?.payment?.note" class="text-gray-500 my-3">
        {{ sitedata?.payment?.note }}
      </p>
    </div>
    <UAccordion
      :items="paymentMethodsItems"
      :ui="{
        wrapper: 'flex flex-col w-full',
      }"
    >
      <template #item="{ item }">
        <p class="text-lg pl-12">
          {{ _get(item, `content${locale === 'vi' ? '' : `_${locale}`}`) ?? _get(item, 'content') }}
        </p>
      </template>
      <template #default="{ item }">
        <UButton
          color="gray"
          variant="ghost"
          class="border-b border-gray-200"
          size="xl"
          :ui="{
            rounded: 'rounded-none',
            padding: { sm: 'p-3' },
          }"
          @click="onSelect(item?.value)"
        >
          <template #leading>
            <div class="w-6 h-6 rounded-full bg-primary-500 flex items-center justify-center -my-1">
              <URadio
                v-model="selected"
                :value="item?.value"
                :ui="{
                  base: 'h-6 w-6',
                }"
                @click="onSelect(selected)"
              />
            </div>
          </template>

          <span class="truncate text-xl">
            {{ _get(item, `label${locale === 'vi' ? '' : `_${locale}`}`) ?? _get(item, 'label') }}
          </span>
        </UButton>
      </template>
    </UAccordion>
  </div>
</template>
